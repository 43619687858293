<template>
  <f7-page name="photocrop" class="" style="background-color: #F6F6F6;overflow-x: hidden;">
    <!-- Top Navbar -->
    <f7-navbar :sliding="true">
      <!--<f7-nav-left>-->
      <!--<f7-link icon-ios="f7:back" icon-aurora="f7:back" icon-md="material:back" panel-open="left"></f7-link>-->
      <!--</f7-nav-left>-->

      <!--<f7-nav-left>-->
      <!--<f7-link>Left Link</f7-link>-->
      <!--</f7-nav-left>-->
      <f7-nav-left :back-link="true" class="back-icon" @back-click="this.callback"></f7-nav-left>
      <f7-nav-title sliding>照片上传/编辑</f7-nav-title>
      <!--<f7-nav-right>-->
      <!--<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="right"></f7-link>-->
      <!--</f7-nav-right>-->
      <!--<f7-nav-title-large sliding>tbimg</f7-nav-title-large>-->
    </f7-navbar>

    <!--<div class="mask-shadow" :style="'width:'+image.frameWidth+'px;height:'+image.frameHeight+'px;top:'+(maskTopTop+maskHeight)+'px;left:'+maskWidth+'px;'"></div>-->
    <!--<div class="mask-border" :style="'width:'+image.frameWidth+'px;height:'+image.frameHeight+'px;top:'+(maskTopTop+maskHeight)+'px;left:'+maskWidth+'px;'"></div>-->

    <!--<img src="https://image-baidu.oss-cn-beijing.aliyuncs.com/2020/03/1244302798674333696.jpg?x-oss-process=image/rotate,0">-->

    <!--
    todo 适应的时候同时居中
    todo 最后的裁切函数
    todo 开始的时候根据有参数自动初始化图片的位置和角度
    -->

    <!--<img :src="option.img" alt="" @load="imgLoaded" style="width:1px;height:1px;">-->
    <div class="cropper-content">
      <div class="cropper" :style="{'height':option.containerHeight+'px'}">
        <template v-if="option.img">
        <vueCropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.size"
                :outputType="option.outputType"
                :info="true"
                :canScale="option.canScale"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :full="option.full"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :centerBox="option.centerBox"
                :infoTrue="option.infoTrue"
                :fixedBox="option.fixedBox"
                :maxImgSize="option.maxImgSize"
                @imgLoad="imgLoad"
                @imgMoving="imgMoving"
                @cropMoving="cropMoving"
                @realTime="realTime"
                @imgGet="test"
        ></vueCropper>
        </template>
      </div>
    </div>
                <!-- :trueWidth="image.w"
                :trueHeight="image.h" -->

    <!--老的-->
    <!--<div style="display:flex;flex-direction: column;height:100%;">-->
      <!--<div class="crop-container" style="" ref="cropPhotoframe">-->
        <!--<div class="photo-item-img-inner-relative" :style="'width:'+image.frameWidth+'px;height:'+image.frameHeight+'px;'">-->
            <!--<img class="photo-item-img-img" :src="image.imageUrl" :style="'width:'+image.imgWidth+'px;left:'+image.imgLeft+'px;top:'+image.imgTop+'px;'" draggable="true" />-->
        <!--</div>-->
      <!--</div>-->
      <!--<div style="height:133px;"></div>-->
    <!--</div>-->
    <div style="margin: 180px auto 0 auto;height:133px;background-color: #fff;position: fixed;bottom: 0;left: 0;right:0;z-index:19;">
      <div>
        <!--操作按钮-->
        <div style="height: 60px;display: flex;flex-direction: row;justify-content: space-around;">
          <!--left-->
          <img v-if="isFirst" src="static/img/crop/crop-left-dis.png" />
          <img v-else @click="preImage" data-type="-" src="static/img/crop/crop-left.png" />

          <!--rotate-->
          <img v-if="isShowRotate && !propReadonly" @click="rotateImage" src="static/img/crop/crop-rotate.png" />
          <img src="static/img/crop/crop-rotate-dis.png" v-else />

          <img v-if="!propReadonly" @click="setImageAdjust" src="static/img/crop/crop-auto.png" />
          <img src="static/img/crop/crop-auto-dis.png" v-else />

          <!--right-->
          <img v-if="isLast" src="static/img/crop/crop-right-dis.png" />
          <img v-else @click="nextImage" data-type="+" src="static/img/crop/crop-right.png" />
        </div>
        <!--底部按钮-->
        <div style="display: flex;flex-direction: row;align-items: center;height:73px;line-height: 73px;">
          <div style="width:100px;height:100%;background-color: #E5E5E5;text-align: center;color:#333;" @click="deleteImage" v-if="!propReadonly">
            删除
          </div>
          <div style="height:100%;flex-grow:1;background-color: #0084FF;color:#fff;text-align: center;" @click="submitImage">
            完成
          </div>
        </div>
      </div>
    </div>


    <!--样例图-->
    <!--<img src="static/exp/photocrop.png" alt="" width="100%;" style="margin: 150px 0">-->


  </f7-page>
</template>
<script>
  import VueCropper from '../components/cropper/vue-cropper.vue';
//  Vue.use(VueCropper)
  export default {
    props:{
      propImage:{
        type:Object,
        default(){
          return {}
        }
      },
      propImageList:{
        type:Array,
        default(){
          return []
        }
      },
      propReadonly:{
        type:Boolean,
        default(){
          return true
        }
      },
      propOrderGoodsId:{
        type:Number,
        default(){
          return 0
        }
      },
      callback:{
        type:Function,
        default(){
          return function () {
            console.log("no callback")
          }
        }
      }
    },
    components:{
      VueCropper
    },
    data() {
      return {
        image:{},
        imageList:[],
        frameMaxWidth:1,//图片框架实际宽度，从photoframe获取
        frameMaxHeight:2,//图片框架实际高度，从photoframe获取

        paddingWidth:20,//侧边保留（单侧距离）
        paddingHeight:20,//侧边保留（单侧距离）

        maskTopTop:0,
//        maskBottomBottom:null,
        maskHeight:0,
        maskWidth:0,
//        maskLeftTop:null,
//        maskLeftBottom:null,

        adjustWidth:true,//按宽度适应，也就是图片宽度等于裁切宽度

        cropper:null,


        option: {
          img: '', // 裁剪图片的地址
//          img:'http://cdn.xyxiao.cn/Portrait_2.jpg',
//          img:'http://cdn.xyxiao.cn/Portrait_2.jpg?x-oss-process=image/rotate,0',
          info: false, // 裁剪框的大小信息
          outputSize: 0.8, // 裁剪生成图片的质量
          outputType: 'jpeg', // 裁剪生成图片的格式
          canScale: true, // 图片是否允许滚轮缩放_手机端缩放
          autoCrop: true, // 是否默认生成截图框
//          autoCropWidth: 1000, // 默认生成截图框宽度
//          autoCropHeight: 1000, // 默认生成截图框高度
          fixedBox: true, // 固定截图框大小 不允许改变
          fixed: true, // 是否开启截图框宽高固定比例
          fixedNumber: [7, 5], // 截图框的宽高比例
          full: false, // 是否输出原图比例的截图
          canMoveBox: false, // 截图框能否拖动
          original: false, // 上传图片按照原始比例渲染
          centerBox: false, // 截图框是否被限制在图片里面
          infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
          maxImgSize:100000,//限制图片最大宽度和高度

          containerHeight:500,//容器高度
        },

        cropperRotated:false,


        minPadding: 50,//swan.getSystemInfoSync().windowWidth > 320 ? 50 : 30,
        // minPadding: swan.getSystemInfoSync().windowWidth > 320 ? 40 : 20,
        goodsData: {},
        curImage: "",
        dataList: [],
        pixelRatio: 1,//swan.getSystemInfoSync().pixelRatio,
        winW: 375,//swan.getSystemInfoSync().windowWidth,
        windowHeight: 667,//swan.getSystemInfoSync().windowHeight,
        // Lz *******  解决刘海问题.................
        winH: 600,//swan.getSystemInfoSync().windowHeight - BUTTOM_HEIGHT,
        catH: 20,
        catW: 20,
        paddingH: 20,
        paddingW: 20,
        catDirection: 0,
        btnTop: 67,//swan.getSystemInfoSync().windowHeight - BUTTOM_HEIGHT,
        catModalData: {},

        isShowRotate: true,

        xStart: 0,
        yStart: 0,
        touchesList: [],

        scale: 1,
        curShowType: 0,

        x: 0,
        y: 0,
        w: 50,
        h: 100,
        rotate: 0,
        curRatio: 1,
        afterLoad:null,
        testcontent:'test',
        screenLoading:false,
        screenLoadigText:'',
      }
    },
    beforeRouteLeave(to, from, next) {
      // 设置下一个路由的 meta
//      to.meta.keepAlive = false; // 不缓存，即刷新
      consle.log(to,from,next)
      next();
    },
    mounted(){
      this.$f7ready((f7) => {
        console.log("---",this.$f7route.name,"mounted",this.propReadonly)

//        this.frameMaxWidth = this.$refs.cropPhotoframe.offsetWidth-this.paddingWidth*2
//        this.frameMaxHeight = this.$refs.cropPhotoframe.offsetHeight-this.paddingHeight*2
//        console.log("photoframe:",this.frameMaxWidth,this.frameMaxHeight)
//        this.initImage(this.image)
//        this.initMask(this.image)
        this.image=this.propImage
        // this.image="https://imagepdd.gotomai.com/2021/06/1400457977529503744.jpg?x-oss-process=image/rotate,0/quality,Q_41&v=1"
//        debugger
        this.imageList = this.propImageList
        console.log('propOrderGoodsId:',this.propOrderGoodsId)

//        初始化切图框
        let pageHeight=window.document.body.clientHeight
        this.option.containerHeight=pageHeight-133-45
        console.log("mounted: pageHeight,containerHeight ",pageHeight,this.option.containerHeight)

        if(this.propReadonly){
          this.option.canScale=false
          this.option.canMoveBox=false
          this.option.canMove=false
        }

        this.loadThisImage()
//        this.cropperLoadingStart()
      });
    },
    activated(){
      console.log("---",this.$f7route.name,"activated")
    },
    computed:{
      isFirst(){
        if(this.imageList.length==0){
          return true
        }
        return this.image.id==this.imageList[0].id
      },
      isLast(){
        if(this.imageList.length==0){
          return true
        }
        return this.image.id==this.imageList[this.imageList.length-1].id
      },
      curIndex(){
        for(let i=0;i<this.imageList.length;i++){
          if(this.imageList[i].id==this.image.id){
            return i
          }
        }
      },
    },
    methods: {
      test(testcontent){
        console.log("test:",testcontent)
        this.testcontent = testcontent
      },
      loadThisImage(){
        console.log("~loadThisImage")
        this.option.fixedNumber = [this.image.width, this.image.height]
        this.option.img = this.image.listImageUrl + '&v=1'
        this.afterLoad = this.waitingForCropperIniting
        this.loading()
      },
      loading(text){
        text = text||'加载中'


        if(this.screenLoading && this.screenLoadigText==text){
          // loading中，并且text没变
          console.log("~~loading 0:",text)
          return
        }
        this.loadingClose()
        console.log("~~loading 1:",text,this.screenLoading,this.screenLoadigText)
        this.screenLoading = true
        this.screenLoadigText=text
        this.$f7.dialog.preloader(text)
      },
      loadingClose(){
        this.$f7.dialog.close()
        this.screenLoading=false
      },
      waitingForCropperIniting(){
        this.loading("图片预处理中")
        // var c=""
        this.cropper = this.$refs.cropper
        // if(this.cropper){
        //   var a = [
        //     this.cropper.cropW || "",
        //     this.cropper.cropOffsertX || "",
        //     this.cropper.cropH,
        //     this.cropper.getCropAxis() || "",
        //     this.cropper.getCropAxis() ? this.cropper.getCropAxis().y1 : ".2",
        //     Math.abs(this.cropper.getCropAxis().y1*2+this.cropper.cropH-this.option.containerHeight)
        //   ]
        //   c = "图片预处理中,"+a.join("<br/>")
        // }else{
        //   c="图片预处理中11"
        // }
        // this.loading(c)
        if(this.cropper
          && this.cropper.cropW>0
          && this.cropper.cropOffsertX>0
          && this.cropper.cropH>0
          && this.cropper.getCropAxis()
          && (this.cropper.getCropAxis().y1 || this.cropper.getCropAxis().y1==0)//y1==0也是可以的，不应该判定为false，2021-7-13修改
          && Math.abs(this.cropper.getCropAxis().y1*2+this.cropper.cropH-this.option.containerHeight)<2//计算cropper是否初始化完成
        ){
          // console.log('切图框比例：',this.cropper.cropW,this.cropper.cropH,this.cropper.cropW/this.cropper.cropH, '图片比例：',this.image.width, this.image.height,this.image.width/this.image.height)
          this.cropperLoadingEnd()
          // setTimeout(this.cropperLoadingEnd,3000)
          // setTimeout(this.waitingForCropperIniting,50)
        }else{
          setTimeout(this.waitingForCropperIniting,50)
        }
      },
      cropperLoadingEnd() {
        this.loading("图片处理中")

        let imageRotate = this.getImageRotateByUrl()
        if (imageRotate == 0 || imageRotate == 180) {
          //旋转：未旋转
          this.image.wreal = this.image.w
          this.image.hreal = this.image.h
          console.log("cropperLoadingEnd: 旋转：未旋转")
        } else {
          //旋转：旋转了
          this.image.wreal = this.image.h
          this.image.hreal = this.image.w
          console.log("cropperLoadingEnd: 旋转：旋转了")
        }

        this.loadingClose()
//        this.cropper = this.$refs.cropper
        console.log('cropperLoadingEnd: cropper',this.$refs.cropper)
        // 设置缩放比例
        // 裁切框的实际宽度/冲印宽度 = 图片缩放后宽度/图片的实际宽度 = 缩放比例
//        debugger
        let scale=this.$refs.cropper.cropW/this.image.width
//        scale=1
        // if(!scale){
        //   debugger
        // }
        this.cropper.scale = scale
        console.log("cropperLoadingEnd: scale",scale)

        let cropX1 = this.cropper.getCropAxis().x1
        let cropY1 = this.cropper.getCropAxis().y1
        // cropY1 = this.$refs.cropper.getCropAxis().y1
        // cropY1 = (this.option.containerHeight - this.cropper.cropH)/2
        console.log("cropperLoadingEnd: cropX1,cropY1",cropX1,cropY1)
        console.log('cropY1是否正确，计算容器高度：',this.cropper.cropH+cropY1*2,"，实际容器高度：",this.option.containerHeight)

        // 设置偏移：

        // 如果scale等于1，x=0，则图片靠容器左边
        this.cropper.x=0

        // 还是scale等于1的前提下，如果想让图片靠裁切框左边，就加上裁切框的x1
        this.cropper.x=cropX1

        // 如果scale!=1，比如小于1，则图片是以图片中心缩放的，如果希望图片靠容器左边，则需要往左偏移
        // 偏移的距离等于w/2-w*scale/2，该公式也适用于scale大于等于1的情况
        this.cropper.x=-(this.image.wreal/2-this.image.wreal*scale/2)

        // 同样，如果图片左侧贴近裁切左侧，则加上裁切左边的x1
        this.cropper.x=cropX1-(this.image.wreal/2-this.image.wreal*scale/2)

        // 最终的x，图片需要比裁切框再偏移paramX，paramX为正代表图片向左偏移
        this.cropper.x = this.$refs.cropper.getCropAxis().x1-(this.image.wreal/2-this.image.wreal*scale/2)-this.image.paramX*scale

        // 仿造x写出y
        this.cropper.y = cropY1-(this.image.hreal/2-this.image.hreal*scale/2)-this.image.paramY*scale

        console.log("cropperLoadingEnd: x,y:",this.cropper.x,this.cropper.y)

//        debugger
//        this.cropper.y = cropY1-(this.image.hreal/2-this.image.hreal*scale/2)
//        debugger
//        this.$refs.cropper.refresh()
        this.$f7.dialog.close()
        this.cropperInfo()
      },
      imgLoad(e){
        // this.loading(e)
        let vm=this
        setTimeout(()=>{
          this.afterImgLoad(e)
        },1)
      },
      afterImgLoad(e){
        console.log('imgLoad:',e)
        if(e=='error'){
          console.log("error,reinit")
          this.loading("照片加载失败:"+e)
          return
        }
        if(this.afterLoad){
          if(typeof(this.afterLoad)=="function"){
            this.afterLoad()
            this.afterLoad = null
          }
        }
      },
      reInitCropper(){
        console.log("reInitCropper")
      },
      cropperInfo(){
        console.log("-------- cropperInfo ----------")
//        console.log("裁切对象:",this.$refs.cropper)
        console.log("-- getCropAxis",this.$refs.cropper.getCropAxis())
        console.log(
            "-- 画布宽/高:",this.$refs.cropper.w,this.$refs.cropper.h,
            "截图框宽/高:",this.$refs.cropper.cropW,this.$refs.cropper.cropH,
        )

        console.log("-- getImgAxis:",this.$refs.cropper.getImgAxis())
        console.log(
            "-- 图片实际宽/高",this.$refs.cropper.trueWidth,this.$refs.cropper.trueHeight,
            "图片缩放后宽/高:",this.$refs.cropper.trueWidth*this.$refs.cropper.scale,this.$refs.cropper.trueHeight*this.$refs.cropper.scale,
            "图片缩放比例:",this.$refs.cropper.scale
        )
        console.log("-- 图片x,y,r(相对于画布):",this.$refs.cropper.x,this.$refs.cropper.y,this.cropper.rotate)
//        console.log("-- 预计保存信息",
//            this.calcSaveInfo()
//        )
      },
      // 图片移动事件
      imgMoving(e){
        if(!e.moving){
          console.log("移动结束：",e.axis)
//          this.$refs.cropper.cropW 截图框宽度
//          this.$refs.cropper.cropH 截图框高度
//          this.cropperInfo()
        }
      },
      // 裁切移动事件
      cropMoving(e){
        console.log('cropMoving',e)
      },
      // 实时监控
      realTime(e){
//        console.log("realTime:",e)
      },
      rePullImages(cb) {
        console.log("~~rePullImages")
        this.$http.post('/ordergoods/getImages', this.$qs.stringify({
          orderGoodsId: this.propOrderGoodsId
        })).then(res => {
//          debugger
          if (res.data.status == 1) {
            this.imageList = res.data.data
          }
          if(cb){
            cb()
          }
        }).catch(res => {

        })
      },
      // 上一张
      preImage(){
//        let _this = this
        if(!this.isFirst){
          if(this.propReadonly){
            this.image = this.imageList[this.curIndex-1]
            this.loadThisImage()
          }else{
            this.submitImage(()=>{
              this.rePullImages(()=>{
                this.image = this.imageList[this.curIndex-1]
                this.loadThisImage()
              })
            })
          }
        }
      },
      // 下一张
      nextImage(){
//        let _this = this
        let vm=this
        if(!this.isLast){
          if(this.propReadonly){
            this.image = this.imageList[this.curIndex+1]
            this.loadThisImage()
          }else{
            this.submitImage(()=>{
              this.image = this.imageList[this.curIndex+1]
              this.loadThisImage()
              this.rePullImages()
              // setTimeout(function(){
              //     vm.loadThisImage()
              // },1000)
              // this.rePullImages(()=>{
              //   // this.$nextTick(()=>{
              //   //   this.loadThisImage()
              //   // })
              // })
            })
          }
        }
      },
      getImageRotateByUrl(){
//        let rotate = Number(this.image.listImageUrl.split(",")[1].split("/")[0])

        var rotate = Number(this.image.listImageUrl.match(/rotate,\d+/)[0].split(",")[1])
//        debugger
        console.log('getImageRotateByUrl:',this.image.listImageUrl,rotate)
        return rotate
      },
      // 旋转图片
      rotateImage(){
        console.log("rotateImage");

        // 用组件的旋转
        // this.$refs.cropper.rotateRight()

        //设定200毫秒后对图片进行自适应操作，太快的话，init还没完成
        // setTimeout(this.setImageAdjust,200)
        let url = this.image.listImageUrl
        console.log('original:',url)
        let imageRotateOriginal = this.getImageRotateByUrl()
        let imageRotateNew = (imageRotateOriginal+90)%360
        let urlNew = url.replace("rotate,"+imageRotateOriginal,"rotate,"+imageRotateNew)
        console.log('new:',urlNew)
        this.image.listImageUrl = urlNew
        this.image.rotate = imageRotateNew

        this.option.img = this.image.listImageUrl + '&v=1'

        this.afterLoad = function(){
          this.cropperLoadingEnd()
          this.setImageAdjust()
        }
        this.loading()

        // 直接加载服务器旋转后的照片
//        this.option.img=this.image.listImageUrl+'&v=1'

//        setTimeout(this.setImageAdjust,550)
//        this.setImageCenter()
      },
      // 旋转裁切框
      rotateCropper () {
        console.log("rotateCropper");
//        this.$refs.cropper.rotateRight()
//        this.option.fixedNumber=[this.image.height,this.image.width]
        this.cropperRotated=!this.cropperRotated
        this.cropperLoadingEnd()

        //设定200毫秒后对图片进行自适应操作，太快的话，init还没完成
        setTimeout(this.setImageAdjust,200)
//        this.option.img=this.image.listImageUrl+'&v=1'
//        this.cropperInfo()
      },
      // 图片按裁切框中心居中
      setImageCenter(){
        console.log("setImageCenter")
//        debugger
        //宽度居中
        this.cropper.x=-this.image.wreal/2+this.cropper.w/2
        //高度居中
        this.cropper.y=-this.image.hreal/2+this.cropper.h/2
      },
      // 自动将图片按裁切框的宽/高适应，并将图片居中
      setImageAdjust () {
        console.log("setImageAdjust")
        if(this.adjustWidth){
          this.$refs.cropper.scale=this.$refs.cropper.cropW/this.$refs.cropper.trueWidth
        }else{
          this.$refs.cropper.scale=this.$refs.cropper.cropH/this.$refs.cropper.trueHeight
        }
        this.adjustWidth = !this.adjustWidth
        this.setImageCenter()
      },
      calcSaveInfo(){
        // 照着正向的公式反向推算出paramX和paramY
        // this.cropper.x = this.cropper.getCropAxis().x1-(this.image.wreal/2-this.image.wreal*scale/2)-this.image.paramX*scale
        // this.cropper.y = this.cropper.getCropAxis().y1-(this.image.hreal/2-this.image.hreal*scale/2)-this.image.paramY*scale
        let imageRotate = this.getImageRotateByUrl()

        let scale = this.cropper.scale
        let paramX = (this.cropper.getCropAxis().x1-(this.image.wreal/2-this.image.wreal*scale/2)-this.cropper.x)/scale
        let paramY = (this.cropper.getCropAxis().y1-(this.image.hreal/2-this.image.hreal*scale/2)-this.cropper.y)/scale

        // 还要根据scale算一下width和height
        // 前面说过，裁切框的实际宽度/冲印宽度 = 图片缩放后宽度/图片的实际宽度 = 缩放比例
        // let scale=this.$refs.cropper.cropW/this.image.width
        // 那么反过来算出width和height
        let imageWidth = this.cropper.cropW/scale
        let imageHeight = this.cropper.cropH/scale

        let rotateFormat = this.cropper.rotate*90+this.image.rotate
        let paramsStr=this.$qs.stringify({
          id:this.image.id,
          x:Math.floor(paramX),
          y:Math.floor(paramY),
          width:Math.floor(imageWidth),
          height:Math.floor(imageHeight),
          rotate:rotateFormat,
        })
        return paramsStr
      },
      // 提交到后台
      submitImage (nextAction) {
        let vm=this
        if(this.propReadonly){
          vm.callback()
          vm.$f7router.back()
          return
        }
        let paramsStr = this.calcSaveInfo()
        console.log('按参数更新：',paramsStr)
        this.loading()
        this.$http.post('/ordergoods/image/updateCutArea', paramsStr).then(res => {
          console.log("----", res)
          vm.loadingClose()

          if(nextAction && typeof(nextAction)=="function"){
            nextAction()
          }else{
            vm.callback()
            vm.$f7router.back()
//          this.$f7router.navigate('/photolist/')
            if(res.data.status==1){

            }else{

            }
          }
        }).catch(res => {
          vm.callback()
          vm.$f7router.back()
        })
      },
      // 删除
      deleteImage () {
        if(this.propReadonly){
          return
        }
        this.$f7.dialog.confirm('确定删除？','', (e=>{
          //确定

          this.$f7.dialog.preloader('删除中');

          this.$http.post('/ordergoods/image/removePhoto', this.$qs.stringify({
            id:this.image.id,
          })).then(res => {
            console.log("----", res)
//          this.$f7router.navigate('/photolist/')

            this.$f7.dialog.close();
            this.callback()
            this.$f7router.back()

//          if(res.data.status==1){
//            this.callback()
//            this.$f7router.back()
//          }else{
//
//          }
          }).catch(res => {
            this.$f7.dialog.close();
          })
        }),(e=>{
          //取消
        }))

      },
      /**
       * 点击裁剪，这一步是可以拿到处理后的地址
       * 目前没用
       */
      finish() {
        this.$refs.cropper.getCropBlob((data) => {
//          var fileName = 'goods' + this.fileinfo.uid
          console.log(data)
//          this.loading = true
          //上传阿里云服务器
//          client().put(fileName, data).then(result => {
//            this.dialogVisible = false
//            this.picsList.push(result.url)
//          }).catch(err => {
//            console.log(err)
//            this.loading = false
//          })
        })
      },
      /**
       * 计算裁切框大小及方向坐标, isDirection: 横向(1)、纵向(2)
       * 目前没用
       */
      setModal() {
        var { winH, winW, windowHeight, minPadding, dataList, curIndex } = this.data;
        var { imageWidth: iW, imageHeight: iH, imageDirection: iDir } = this.data.goodsData;
        var { w, h } = dataList[curIndex];
        const isDirection = w >= h ? 1 : 2; // isDirection: 横向(1)、纵向(2)
        var catW = 20, catH = 20;
        const A = iW / iH, B = winW / winH; //  裁切 纵向时的比例问题.
        var paddingW = 20, paddingH = 20;//定制左右间距最小20;
        var type = +iDir || isDirection;// 处理照片的方式  --> 横向(1)、纵向(2)
        console.error(winH, windowHeight);
        var max = Math.max(iW, iH), min = Math.min(iW, iH);

        // 开始处理照片....
        if (type == 1) {
          // 处理横向的照片.
          paddingW = Math.max(winW * 0.05, minPadding);
          catW = winW - paddingW * 2; // 确定裁切框 宽度
          catH = Math.ceil(catW * min / max);
          paddingH = (winH - catH) / 2;
        } else {
          // 处理纵向的照片.
          if (A >= B) {
            paddingW = Math.max(winW * 0.05, minPadding);
            catW = winW - paddingW * 2; // 确定裁切框 宽度
            catH = Math.ceil(catW * max / min);
            paddingH = (winH - catH) / 2;
          } else {
            paddingH = Math.max(winH * 0.05, minPadding);
            catH = winH - paddingH * 2;// 确定裁切框 高度度
            catW = Math.ceil(catH * min / max);
            paddingW = (winW - catW) / 2;
          }
        }
        // console.error("catH: /s, catW: /s", catH, catW, paddingW, paddingH);
        this.setData({ catH, catW, paddingW, paddingH });
      },
      /**
       * 计算图片大小、位置信息;
       * 目前没用
       */
      setPage (isFirst = false) {
        var { catH, catW, paddingW, paddingH, x, y, w, h, rotate, curRatio, dataList, curIndex } = this.data;
        var { imageWidth: iW, imageHeight: iH, imageDirection: iDir } = this.data.goodsData;
        var { w: imgW, h: imgH, width: imgWidth, rotate: imgRotate, height: imgHeight, paramX, paramY } = dataList[curIndex];

        rotate = imgRotate
        // 通过裁切区域比较.  计算出比例curRatio
        var curRatioHeight = +((imgHeight / catH).toFixed(4));
        var curRatioWidth = +((imgWidth / catW).toFixed(4));
        curRatio = Math.min(curRatioHeight, curRatioWidth);
        console.error(curRatioHeight, curRatioWidth, "curRatioHeight, curRatioWidth");

        // 通过 curRation  计算出图片实际大小
        w = imgW / curRatio;
        h = imgH / curRatio;

        if (isFirst) {
          if (imgHeight == imgH) {
            h = catH;
            w = imgW * catH / imgH;
          } else if (imgWidth == imgW) {
            w = catW;
            h = imgH * catW / imgW;
          }
        }

        // 通过curRation  计算出图片坐标
        x = paddingW - paramX / curRatio;
        y = paddingH - paramY / curRatio;

        console.error(catH, h, catW, w, 'setPage');
        this.setData({ x: x, y: y, h: h, w: w, rotate, curRatio });
        this.checkImgPage();
      },
    },
  }
</script>
<style type="text/css" lang="less" scoped>
  .page-content {
    overflow-x: hidden;
  }

  input::-webkit-input-placeholder { /* WebKit browsers 适配谷歌 */
    color: #C1CAD3;
  }

  .back-icon a {
    color: #151515;
  }

  /*vue-cropper*/
  .cropper{
    text-align:center;
    width:auto;
  }

  .crop-background {
    background-color: #9D9D9D;
    color: #fff;
  }
  /*遮照*/
  .mask-shadow{
    position: fixed;
    z-index: 9;
    /*阴影*/
    box-shadow: 0 0 0 2000px rgba(59, 59, 59, 0.5);
  }
  /*裁切线*/
  .mask-border{
    position: fixed;
    z-index: 10;

    /*border*/
    border: 2px dashed rgba(0, 255, 3,1);

    /*border不占宽度*/
    box-sizing:border-box;
    -moz-box-sizing:border-box; /* Firefox */
    -webkit-box-sizing:border-box; /* Safari */
  }

  .crop-container {
    /*width:100%;*/
    flex-grow: 1;
    /*border:3px solid #333;*/

    /*background-color: #9D9D9D;*/
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  /*实际冲印大小，用于自动分布位置*/
  .photo-item-img-inner-relative{
    position: relative;
    /*border: 1px solid #999;*/
    background-color: #fbfbfb;
  }

  /*实际冲印大小*/
  .photo-item-img-inner-absolute{
    position: absolute;
    /*overflow: hidden;*/
    width:100%;
    /*border: 1px solid #999;*/
    border: 2px dashed green;
    background-color: #fbfbfb;
  }
  .photo-item-img-img{
    position: absolute;
  }

  .crop-top {
    text-align: center;
    flex-grow:1;
  }

  .crop-middle {
    display: flex;
    flex-direction: row;

    .crop-left {
      display: flex;
      align-items: center;
      align-content: center;
      flex-grow: 1;
    }
    .crop-content {
      background-color: #fff;
      flex-grow: 1;

      display: flex;
      align-items: center;
    }
    .crop-right {
      display: flex;
      align-items: center;
      align-content: center;
      flex-grow: 1;
    }
  }

  .crop-bottom {
    flex-grow:1;
    text-align: center;
  }
  /*.vue-cropper{*/
    /*background: none!important;*/
  /*}*/
  /*.cropper-modal{*/
    /*background-color: #9C9C9C!important;*/
    /*opacity: 1!important;*/
  /*}*/
  /*.cropper-crop-box,.cropper-view-box{*/
    /*background-color: #ffffff!important;*/
  /*}*/

</style>